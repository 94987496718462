@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;600;700;800;900&family=Space+Grotesk:wght@400;500;700&display=swap');

.louter-container {
    display: flex;
    flex-direction: column;
    /* width: 100vw; */
    height: 100vh;
    padding: 20px 120px 20px 200px;
    margin-bottom: 20px;
}

.lheading {
    display: flex;
    justify-self: center;
    font-size: 40px;
    font-family: Space Grotesk;
    font-weight: 600;
}


.subfilterdiv {
    width: 300px;
    height: 50px;
    border: 2px solid gray;
    border-radius: 10px;
    padding: 8px;
    font-family: Space Grotesk;
}

.filterdiv {
    display: flex;
    gap: 40px;
    align-items: center;
}

.ltop-section {
    display: flex;

}

.filtertxt {
    font-family: Space Grotesk;
    font-weight: 500;
    font-size: 20px;
    cursor: pointer;
}

.ltopfield1 {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.filtertxt {
    font-family: Space Grotesk;
}

.logstablecontainer {
    gap: 20px;
    display: flex;
    height: auto;
    margin-top: 80px;
    flex-direction: column;
}

.logstableheading {
    font-family: Space Grotesk;
    font-weight: 600;
    font-size: 30px;
}

.rdt_TableCell {
    font-weight: bold;
    text-align: center;
    padding-left: 200px;
}

.logstableheading {
    font-weight: bold;

}

.rdt_TableCell-center {
    text-align: center;
}

.rdt_Table {
    margin: auto;
    text-align: center;

}

.ant-picker-range .ant-picker-range-separator {
    /* Add your custom styles here */
    padding: 0 36px;
    /* For example, changing the fill color to blue */
}

.ant-picker .ant-picker-separator {
    font-size: 20px;
    margin-bottom: 15px;
    margin-left: -5px;
}



.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70vh;
    width: 100%;
    margin-bottom: 20px;
    /* Set the width to cover the entire viewport */
    background-color: rgba(255, 255, 255, 0.7);
    /* Add a semi-transparent background */
}

.filtertxtbtn {
    width: 100px;
    border-radius: 10px;
    height: 45px;
    transition: 0.5s;
    font-family: Space Grotesk;
    font-weight: 600;
}

.filtertxtbtn:hover {
    transform: scale(1.02);
}



@media (max-width:1300px) {
    .ltopfield1 {
        flex-direction: column;
    }

    .filterdiv {
        flex-direction: column;
        align-items: center;
    }

    .louter-container {
        align-items: center;
    }
}