#box {
    width: 900px;
    margin-left: 200px;
    background-color: white;
    height: 600px;
    border-radius: 20px;
}

div#upper {
    margin: 69px 0px 19px 200px;
    display: flex;
    align-items: center;
    width: 331px;
    justify-content: space-between;
}

.wrappper {
    display: flex;
    align-items: space-between;
    height: 80%;

}


#left {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    text-align: center;

}

#con2,
#con1,
#con3 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

#con3 {
    padding-left: 70px;
}

.pt {
    color: #000;

    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Space Grotesk;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.833px;
}

div#button {
    float: right;
    padding-right: 82px;
}

.btc {
    border-radius: 27px;
    background: #4169E1;
    color: white;
    border: none;
    width: 100px;
    height: 50px;
    flex-shrink: 0;
    font-family: Space Grotesk;
    font-size: 19px;
}

.btc:hover {
    filter: brightness(90%);
}

.hd {
    color: #727272;

    font-family: Space Grotesk;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.833px;
}

#right {
    height: 100%;
    width: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column;

}

.pg {
    font-weight: 600px;
    font-size: 40px;
    font-family: Space Grotesk;
}

/* .back {
    width: calc(100% - 75px);
    margin-left: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
} */

@media (max-width: 1178px) {
    #box {
        width: calc(100% - 75px);

        margin-left: 75px;

    }
}

@media (max-width: 590px) {
    #upper {
        margin-left: 100px !important;
    }
}