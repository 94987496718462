@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;600;700;800;900&family=Space+Grotesk:wght@400;500;700&display=swap');




.profile-icon {
    display: flex;
    align-items: center;
    background-color: black;
    color: white;
    padding: 6px;
    border-radius: 50%;
    width: 32px;
    height: 32px;
}

.profile-icon-container {
    display: flex;
    align-items: center;

    width: 100%;

}



.profile-icon-text {
    font-size: 18px;
    margin-left: 10px;
    line-height: 50px;
    font-weight: 400;
}


.react-data-table-component .rdt_TableCell:last-child {
    font-size: 18px;

}

.react-data-table-component .rdt_TableCol {
    font-size: 18px;
}


.touter-container {
    max-width: 1650px;
    margin: 0 auto;
    padding: 20px;
}

.theading {
    font-size: 44px;
    font-weight: bold;
    margin-bottom: 10px;
}

.hr2 {
    border: 0;
    border-top: 1px solid #ccc;
    margin: 10px 0;
}

.profile-icon-container {
    display: flex;
    align-items: center;
}

.profile-icon {
    font-size: 17px;
    margin-right: 10px;
    padding: 8px;
}

.trash-icon-container {
    text-align: center;
}



.role-cell {
    font-size: 18px;
    /* font-weight: bold; */
    cursor: pointer;
}


.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 9999;
}

.popup-content {
    height: 290px;
    background-color: #fff;
    padding: 30px 10px 10px 10px;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 1000px;

    width: 90%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}


.button-container {
    display: flex;
    gap: 40px;

    margin-top: 10px;

}

.popup-content div {
    margin-bottom: 20px;

}

.popup-content button {
    background-color: #4169e1;
    transition: 0.2s;
    color: #fff;
    border: none;
    padding: 8px 16px;
    border-radius: 13px;
    cursor: pointer;
    width: 130px;
}

.popup-content button:hover {
    background-color: #0056b3;
    transform: scale(1.02);
}


.confirmation-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.9);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 9999;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 200px;
    justify-content: center;
    width: 600px;
}

.confirmation-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.confirmation-message {
    font-size: 18px;
    margin-bottom: 10px;
}

.confirmation-icons {
    display: flex;
    gap: 25px;

margin-top: 20px;
}



.confirmation-icons .confirmation-icon {
    display: flex;
    font-size: 20px;
    background-color: rgba(255, 255, 255, 0);
    font-family: Space Grotesk;
    font-weight: 300;
    margin: 0 5px;
    cursor: pointer;
    width: 100px;
    height: 40px;
    justify-content: center;
    align-items: center;
    border: 1px solid black;

}

.confirmation-icons .confirmation-icon-yes {
    color: black;

}

.confirmation-icons .confirmation-icon-no {
    color: white;
    background-color: #0056b3;
    border: none;
    display: flex;
}

.add-member-button {
    height: 40px;
    justify-content: center;
    align-items: center;
    width: 150px;
    background-color: #0056b3;
    color: white;
    font-family: Space Grotesk;
    padding: 5px;
    border-radius: 8px;
    border: none;
    display: flex;
    float: right;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;
    margin-top: 10px;
    transition: 1s;
}

.add-member-button:hover {
    transform: scale(1.02);
    filter: brightness(90%);
}


.memberemaiol {
    width: 905px;
    height: 40px;
    border: 1px solid black;
    border-radius: 8px;
    padding: 8px;
    font-family: Space Grotesk;
}

.emoildiv {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 4px;
}

.rolediv {
    display: flex;
    align-items: center;
    gap: 10px;
}

.error {
    border: 1px solid red;
}

.memberemail {
    width: 900px;
    height: 40px;
    border: 1px solid black;
    border-radius: 8px;

}

.error input,
.error select {
    border: 1px solid red;
}


.error-popup {
    position: absolute;
    background-color: red;
    color: white;
    padding: 3px 8px;
    border-radius: 5px;
    font-size: 12px;
    top: -25px;
    /* Adjust this value to position the pop-up */
    left: 0;
    transform: translateX(-50%);
    z-index: 1;
}

.error-popup+input,
.error-popup+select {
    position: relative;
}