@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;600;700;800;900&family=Space+Grotesk:wght@400;500;700&display=swap');

.loutermost-container2 {
    display: flex;
    padding: 20px 120px 20px 350px;
}

.louter-container2 {
    display: flex;
    flex-direction: column;
    /* width: 100vw; */


    width: 1300px;
}

.lheading2 {
    display: flex;
    justify-self: center;
    font-size: 40px;
    font-family: Space Grotesk;
    font-weight: 600;
}



.logstablecontainer2 {
    gap: 20px;
    display: flex;
    height: auto;
    margin-top: 80px;
    flex-direction: column;
}

.download-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
}

.invoice-cell {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 13px;
}



.kebab-icon {
    display: flex;
    margin-left: 150px;
}

.svgpdf {
    width: 20px;
}