.burger-menu {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    background-color: #4169E1;
    display: flex;
    align-items: center;
    justify-content: center;


}

.burger-menu:hover {
    cursor: pointer;
}

.sidebar-heading {
    font-weight: 400;
    font-size: 17px;
    margin-left: 10px;
    margin-top: 10px;
    color: #adadc9;
}

.toggleswitch {
    margin-top: 46px;
    margin-left: 73px;
}

.setting-opt {
    position: absolute;
    top: 90vh;
    left: 0px;
    height: 10vh;
    width: 215px;
    background: white;
    z-index: 0;
    border-radius: 0px 10px 0px 0px;
    box-shadow: 3px -2px 4px 0px rgba(0, 0, 0, 0.25);
}

.profile-pic {
    border-radius: 45px;
}

.sidebar-heading:hover {
    cursor: pointer;

}

.setting-menu {
    z-index: 100;
}

.img-src1:hover svg path {
    stroke: #4169E1;
}

.img-src:hover svg path {
    fill: #4169E1;
}

.sidebar {
    height: 100%;
    width: 215px;
    background-color: white;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* transition: width 0.3s ease-in; */
}

.img-src {
    height: 55px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
}

.img-src:hover {
    height: 55px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: rgba(65, 105, 225, 0.08);
    cursor: pointer;
}

.img-srcactive {
    height: 55px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: rgba(65, 105, 225, 0.08);
    cursor: pointer;
}

.img-src1active {
    height: 55px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: rgba(65, 105, 225, 0.08);
    cursor: pointer;
}

.img-src1 {
    height: 55px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;

}

.img-src1:hover {
    height: 55px;
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: rgba(65, 105, 225, 0.08);
    cursor: pointer;
}

.icon1,
.icon2,
.icon3,
.icon4,
.icon5,
.icon6,
.icon7 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.icons {
    display: flex;
    flex-direction: column;
    height: 512px;
    justify-content: space-evenly;

    .icons {
        display: flex;
        flex-direction: column;
        height: 512px;
        justify-content: space-evenly;
        gap: 31px;
    }
}