@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;600;700;800;900&family=Space+Grotesk:wght@400;500;700&display=swap');

.pouter-container {
    display: flex;
    flex-direction: column;
    margin: 30px 200px 40px 140px;
    padding: 0px 10px 20px 50px;
    background-color: white;
    border-radius: 15px;
    width: 800px;
    height: 725px;

}

.ptop-section {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    gap: 40px;
}

.ptitle {
    margin-top: 15px;
    font-family: "Space Grotesk";
    font-size: 40px;
    font-weight: bold;
    margin-left: 150px;
}

.field1 {
    align-items: center;
    gap: 40px;
    display: flex;


}

.field2 {
    align-items: center;
    gap: 70px;
    display: flex;

}

.field3 {
    align-items: center;
    gap: 20px;
    display: flex;

}

.field4 {
    align-items: center;
    gap: 45px;
    display: flex;

}

.text1 {
    font-family: Space Grotesk;
    font-weight: bold;
    font-size: 18px;
}

.text2 {
    font-family: Space Grotesk;
    font-weight: bold;
    font-size: 18px;
}

.text3 {
    font-family: Space Grotesk;
    font-weight: bold;
    font-size: 18px;
}

.text4 {
    font-family: Space Grotesk;
    font-weight: bold;
    font-size: 18px;
}

.subtext2 {
    font-weight: bold;
    font-family: Space Grotesk;

}

.subtext3 {
    font-weight: bold;
    font-family: Space Grotesk;

}

.subtext4 {
    font-weight: bold;
    font-family: Space Grotesk;

}

.hr {
    margin-left: -50px;
    width: 41.7vw;
    background-color: #acacac;
}

.pbottom-section {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    gap: 40px;
}

.psubtitle {
    font-family: Space Grotesk;
    font-size: 30px;
    font-weight: bold;
    margin-left: 0px;
}

.botmfield1 {
    align-items: center;
    gap: 40px;
    display: flex;


}

.botmtext1 {
    font-family: Space Grotesk;
    font-weight: bold;
    font-size: 18px;
    display: flex;
}

.botmtext2 {
    font-family: Space Grotesk;
    font-weight: bold;
    font-size: 18px;
    display: flex;

}

.botmfield2 {
    align-items: center;
    gap: 20px;
    display: flex;


}

.deleteimg {
    display: flex;
    align-items: center;
    gap: 30px;
    justify-content: center;

}

.delete {
    font-family: Space Grotesk;
    font-size: 20px;
    cursor: pointer;
}

.deleteprojectbtn {
    display: flex;
    align-items: center;
    justify-content: center;
    float: right;
    background-color: #FF4646;
    color: white;
    font-family: Space Grotesk;
    border: 2px solid black;
    width: 130px;
    height: 40px;
    border-radius: 10px;
    margin-right: 50px;
    font-weight: 100;
    font-size: 12px;
    transition: 0.5s;
}

.deleteprojectbtn:hover {
    transform: scale(1.02);
}

@media(max-width:900px) {
    .pouter-container {
        align-items: center;
        margin-left: 0px;
    }
}

@media(max-width:706px) {
    .text1 {
        font-size: 16px;
    }

    .text2 {
        font-size: 16px;
    }

    .text3 {
        font-size: 16px;
    }

    .text4 {
        font-size: 16px;
    }

    .botmtext1 {
        font-size: 16px;
    }

    .botmtext2 {
        font-size: 16px;
    }


}