.cards {
	height: 350px;
	width: 300px;
	background-color: white;
	border-radius: 15px;
	/* margin-left: 100px; */
	margin-top: 30px;
}

.apexcharts-xaxis-tick {
	display: none;
}

/* .apexcharts-menu-icon {
	display: none;
} */
.apexcharts-toolbar {
	display: none !important;
}

#uk {
	color: black;
}

.sub-op {
	font-size: 13px;
}

.sub1 {
	text-align: center;
}

.sub1 a {
	text-decoration: underline;
}

.link {
	font-size: 14px !important
}

.op1,
.op2 {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 4px;

}

.card-pic1 {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, 0.18);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
}

.card1,
.card2,
.card3 {
	position: relative;
}

.wrap {
	display: flex;
	align-items: flex-start;
	cursor: pointer;
	justify-content: space-around;
	width: 75px;
}

.wrap h4 {
	z-index: 1;

}

.wrap svg {
	z-index: 1;
	color: black;
}

.card1-opt {
	width: 217%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.card2-opt {
	width: 217%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

h4.dayz {
	font-size: 12px;
}

h1 {
	font-weight: 800;
}

h2 {
	font-weight: 800;
}

.subs {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 26px;
	padding-inline: 42px;
}

.titel {
	font-weight: 700;
	font-size: 21px;
}

p.p-type {
	font-size: 16px;
	margin-bottom: 0rem !important;
	color: #a7a8ab;
	font-weight: 300;
}

.comp-det {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
}

h3.company_name {
	font-size: 19px;
	font-weight: 600;
	margin-bottom: 0rem !important;
}

h5.sub_h {
	font-size: 13px;
	line-height: 21px;
	padding-top: 17px;
	font-weight: 500;
	color: #7D7D7D;
}

.ct {
	position: relative;
	margin-bottom: 25px;
}

.k {
	position: absolute;
	display: flex;
	width: 100%;
	top: 243px;
	align-items: center;
	justify-content: space-evenly;
}

.r1,
.r2 {

	display: flex;
	align-items: center;
	justify-content: space-between;
}

.r1 {
	width: 100px;
}

.r2 {
	width: 60px;
}

.l {
	margin-bottom: 0rem !important
}

.obj1,
.obj2 {
	height: 10px;
	width: 10px;
	border-radius: 50%;
	border: none;

}

.obj2 {
	background-color: #4169E1;
}

.y {
	margin-bottom: 0rem !important;
	font-size: 13px;
}

.inf {
	position: relative;
	bottom: 106px;
	left: 113px;
	color: #3E4259;
}

.inf {
	position: relative;
}

.obj1 {
	background-color: #E3E9FB;
}

.days-opt {
	height: 95px;
	width: 96px;
	background-color: white;
	position: absolute;
	border-radius: 10px;
	top: 54px;
	right: 3px;
	z-index: 0;
	box-shadow: 1px 1px 4px 0px rgba(0, 0, 0, 0.25);
	align-items: center;
	justify-content: flex-end;
	flex-direction: column;
	cursor: pointer;

}

._cards {
	width: 100%;
	height: 80%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

._card1 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: rgba(65, 105, 225, 0.07);
	margin-bottom: 16px;
}

.ml {
	padding-left: 20px;
}

._card2 {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: rgba(65, 105, 225, 0.07);
}

.validate {
	font-size: 13px;
}

._card1,
._card2 {
	height: 120px;
	width: 90%;
	border-radius: 20px;
}

div#card4 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
}

.apexcharts-datalabels {
	display: none;
}

.apexcharts-legend.apexcharts-align-center.apx-legend-position-right {
	display: none;
}

.card-pic2 {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	background-color: rgba(255, 79, 121, 0.12);
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
}

.card-heading h3 {
	font-size: 13px;
}

.hg {
	font-size: 25px;
	font-weight: 800;
}

.divinfo {
	width: 100%;
}

.card-heaading {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-inline: 30px;
	margin-top: 50px;
}

.donut1,
.donut2 {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding-top: 13px;
}

._card1_heading {
	padding-left: 20px;
	padding-top: 18px;
}

.card-pic3 {
	height: 50px;
	width: 50px;
	border-radius: 50%;
	background-color: #fff8e7;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 10px;
}

.divinfo1 {
	margin-top: 20px;
	margin-right: 133px;
	color: white;
}

.divinfo2 {
	margin-top: 20px;
	margin-right: 133px;
	color: black;
}

.divinfo3 {
	margin-top: 20px;
	margin-right: 133px;
	color: black;
}

.comp3 {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 1436px;
}

.data_card_1 {
	height: 400px;
	width: 640px;
	background-color: white;
	border-radius: 15px;
}

.data_card_2 {
	height: 400px;
	width: 640px;
	background-color: white;
	border-radius: 15px;
}

.data_card_2 path {
	stroke: none;
}

.card4 path {
	stroke: none;
}