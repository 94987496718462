@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap');

.uheading {
    font-size: 40px;
    font-family: Space Grotesk;
    font-weight: 900;

}

.top {
    display: flex;
    margin: 0px 0px 0px 0px;
    align-items: center;
    justify-content: space-evenly;
    padding-bottom: 50px;
    margin-top: 36px;
}

.btns1 {
    display: flex;
    gap: 25px;

}

.mpurchasebtnmm {
    margin-left: 20px;
    height: 40px;
    width: 157px;
    background-color: #ABABAB;
    color: white;
    font-weight: bolder;
    border: none;
    font-size: 15px;
    border-radius: 10px;
    /* transition: ease-out 0.5s; */
    outline: none;
    box-shadow: inset 0 0 0 0 black;
    font-family: 'Space Grotesk';
    transition: scale 0.5s ease-in-out;

}

.mpurchasebtnmm:hover {
    transition-duration: 0.5s;
    cursor: pointer;
    transform: scale(1.01);
}



.mpurchasebtn:hover {
    box-shadow: inset 300px 0 0 0 rgb(30, 120, 238);
    cursor: pointer;

}

.mpurchasebtnm {
    height: 45px;
    width: 148px;
    font-family: 'Space Grotesk';
    background-color: #4169E1;
    color: white;
    font-weight: 100px;
    /* border: 2px solid black; */
    font-size: 15px;
    border-radius: 11px;
    transition: ease-out 0.5s;
    outline: none;
    /* box-shadow: inset 0 0 0 0 blue; */
    border: none;
}

.mpurchasebtnm:hover {
    box-shadow: inset 300px 0 0 0 blue;
    cursor: pointer;

}

.outer-container {
    display: flex;
    flex-direction: column;
    height: auto;
    padding: 20px 50px;

    /* background-color: #F2F2FD; */

}

.souter-container {

    display: flex;
    flex-direction: column;
    height: auto;
    padding: 20px 50px;
    margin-bottom: 100px;

    align-items: center;
}

.mcard {

    margin: 16px;
    margin-top: 0px;
    transition: 0.3s;
    display: flex;
    width: calc(100% - 0px);
}

/* .mcard:hover {
    border: 3px solid #87CEEB;
    transform: scale(1.01);
} */

.all-content {
    display: flex;
    gap: 200px;
    padding: 10px;
    border-radius: 29px;
    /* border: 3px solid black; */
}


.mcard-content {
    width: auto;
    display: flex;
    margin: 0px 0px 0px 30px;
}

.mcard-title {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 8px;
    font-family: 'Space Grotesk';
    margin-top: 20px;
}

.mcard-description {
    font-size: 16px;
    margin: 20px 0px 0px 0px;

}

.list-description {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.price-section {
    display: flex;
    flex-direction: column;
    gap: 55px;
    align-items: center;
    justify-content: space-evenly;
}


.price {

    display: flex;
    font-weight: 700px;
    font-size: 40px;
    /* margin: 20px 0px 0px 0px; */
    padding-right: 30px;
}



.mpurchasebtn {
    background-color: #4169E1;
    color: white;
    width: 150px;
    height: 40px;
    border-radius: 8px;
    border: 2px solid black;
    font-family: Space Grotesk;
    margin: 0px 0px 0px 30px;
}

.outermost-container {
    display: inline;
}

@media (max-width: 915px) {
    .outer-container {
        padding: 20px 0px 20px 60px;
        align-items: center;
        justify-content: center;
    }

    .souter-container {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 20px 0px 20px 10px
    }


    .top {
        flex-direction: column;
        gap: 50px;
    }

    .all-content {
        gap: 100px;
    }


}