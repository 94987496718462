.testsmsupper {
    width: calc(100% - 75px);
    margin-left: 75px;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
}

.testsmslower {
    width: calc(100% - 75px);
    margin-left: 75px;
    margin-top: 50px;
    display: flex;
    /* align-items: center; */
    justify-content: space-around;
}

.end {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    width: 1500px;
}

.dbz {
    width: calc(95% - 75px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-left: 75px;
}

.ref {
    margin-bottom: 0rem;
    font-size: 13px;
    font-family: 'Space Grotesk';
    font-weight: 300;
}

.c1 {
    text-align: left;
    /* margin-left: 75px; */
}

.c2 {
    margin-right: 75px;
}

.cb {
    display: flex;
    align-items: center;
    width: 136px;
    justify-content: space-between;
    font-size: 16px;
}


.selections {
    display: flex;
    align-items: center;
    margin-top: 55px;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.t1,
.t2 {
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: fit-content;
}

.sel-cont1,
.sel-cont2 {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 50%;
}

@media (max-width: 1450px) {
    .testsmslower {
        width: calc(100% - 75px);
        margin-left: 75px;
        margin-top: 50px;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: space-around;
    }
}

/* @media (max-width:1220px) {
    .selections {
        flex-direction: column;
    }

    .sel-cont1 {
        margin-bottom: 50px;
    }

    .sel-cont1,
    .sel-cont2 {

        width: 100%;
    }
}

@media (max-width: 710px) {
    .pr_select {
        height: 117px;
    }

    .sel-cont1 {
        margin-bottom: 25px;
    }

    .sel-cont1,
    .sel-cont2 {

        flex-direction: column;
    }

} */