@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;600;700;800;900&family=Space+Grotesk:wght@400;500;700&display=swap');



.leftimage {

    margin-left: 180px;
    margin-top: -100px;

}


.imgbtn {
    border: none;
}

.imgbtn:hover {
    color: rgba(65, 105, 225, 0.08);
    cursor: pointer;
}


.rtext {
    display: flex;
    font-family: Space Grotesk;
    font-size: 20px;
}

.bothsection {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;

}

.custom-container {
    display: flex;
    flex-direction: column;
    height: auto;
    /* padding: 20px 50px; */
}

.topsection {
    display: flex;
    height: auto;
    padding: 20px 50px;
    align-items: center;
    gap: 550px;
    margin-left: 80px;
}

.topsectionleft {
    display: flex;
    flex-direction: column;

}

.lefttext {
    font-size: 40px;
    font-family: Inter;
    font-weight: 600;
    margin-left: 250px;
    margin-top: -40px;

}

.rightrectangle {
    height: 200px;
    /* border: 3px solid black; */
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    margin-top: 10px;
    transition: 0.3s;
    display: flex;
    background-color: white;
    width: calc(130% - 0px);
    flex-direction: column;

}

.rtext {
    font-family: Space Grotesk;
    display: flex;
    align-self: center;
}

.rprice {
    align-self: center;
    display: flex;
    margin-top: 40px;
    font-size: 30px;
    font-family: Space Grotesk;
}

.bottomsection {
    display: flex;
    height: auto;
    padding: 20px 50px;
    flex-direction: column;
    margin-left: 300px;
    gap: 10px;

    display: flex;
    flex-direction: column;

    /* Center content horizontally */
    gap: 10px;


    /* Allow bottomsection to grow to fill available space */

}

.bottomtop {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
}

.btext {
    font-family: Space Grotesk;
    font-size: 20px;
    margin-left: 12px;
}

.btrectangle {
    /* justify-content: center; */
    /* align-content: center;height: auto; */
    /* padding: 20px 50px; */
    height: 160px;
    /* border: 3px solid black; */
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    margin-top: 10px;
    transition: 0.3s;
    /* display: flex; */
    background-color: white;
    width: calc(83% - 0px);
    /* flex-direction: column; */
}


.btext2 {
    font-family: Space Grotesk;
    font-size: 20px;
    margin-left: 12px;
}

.btrectangle2 {
    height: 250px;
    /* border: 3px solid black; */
    border-radius: 8px;
    padding: 16px;
    margin: 16px;
    margin-top: 10px;
    transition: 0.3s;
    display: flex;
    background-color: white;
    width: calc(83% - 0px);
    flex-direction: column;
    justify-content: center;

}



.custombtndiv {
    display: flex;
    justify-content: flex-end;
    /* Align content to the end of the container */
    margin-top: auto;
    /* Push the div to the bottom */
    padding-right: 280px;
    /* Optional padding to separate from the right edge */
}

.custombtn {
    border-radius: 27px;
    background: #4169E1;
    color: white;
    border: none;
    width: 150px;
    height: 50px;
    flex-shrink: 0;
    font-family: Space Grotesk;
    font-size: 15px;
    transition: 0.5s;
}

.custombtn:hover {
    filter: brightness(90%);
    cursor: pointer;
    transform: scale(1.01);
}

.slidertxt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.slideritself {
    display: flex;
    /* align-: center; */
    margin-right: 200px;
}




.subtrectangle {
    /* margin-left: 800px; */
    /* position: absolute; */
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.tabs {
    display: flex;
    position: relative;
    background-color: white;
    padding: 0.75rem;
    border-radius: 99px;
    /* border: 2px solid black; */
    z-index: 2;


}

.tabs* {
    z-index: 20;
}

input[type="radio"] {
    display: none;
}

.tab {
    font-family: Space Grotesk;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
    width: 200px;
    font-size: 20px;
    border-radius: 99px;
    cursor: pointer;
    transition: color 0.15s ease-in;
}

input[type="radio"]:checked+label {
    color: white;

}

input[id="radio-1"]:checked~.glider {
    transform: translateX(0);
}

input[id="radio-2"]:checked~.glider {
    transform: translateX(100%);
}

input[id="radio-3"]:checked~.glider {
    transform: translateX(200%);
}


.glider {
    position: absolute;
    display: flex;
    height: 54px;
    width: 200px;
    background-color: #4169E1;
    color: white;
    z-index: -1;
    border-radius: 99px;
    transition: 0.25s ease-out;
}

.sldtxt {
    font-family: Space Grotesk;
    font-size: 30px;
}


@media (max-width:1860px) {
    .topsection {
        gap: 250px;
    }

}

@media (max-width:1550px) {
    .topsection {
        gap: 60px;
    }

}

@media (max-width:1890px) {
    .topsection {
        flex-direction: column;
        gap: 0px;
    }

    .topsectionleft {
        margin-top: 100px;

    }

    .leftimage {
        margin-left: 0px;
    }

    .lefttext {
        margin-left: 50px;
    }

    .rightrectangle {
        width: calc(730% - 0px);
        margin-left: -190px;
    }

}

@media (max-width:615px) {
    .rightrectangle {
        width: calc(190% - 0px);
        margin-left: -70px;
    }
}



@media (max-width:1280px) {
    .btrectangle {
        width: calc(90% - 0px);
    }

    .bottomsection {
        margin-left: 80px;
    }

    .btrectangle2 {
        width: calc(90% - 0px);
    }

}

@media (max-width:885px) {
    .btrectangle {
        width: calc(103% - 0px);
    }

    .bottomsection {
        margin-left: 20px;
    }

    .btrectangle2 {
        width: calc(103% - 0px);
    }

    .glider {
        width: 120px;
    }

    .tab {
        font-size: 13px;
        width: 120px;
    }

    .slideritself {
        margin-right: 100px;
    }

    .sldtxt {

        font-size: 20px;
    }



}

@media (max-width:510px) {
    .tab {
        font-size: 12px;
        width: 90px;
    }

    .glider {
        width: 80px;
    }

    input[id="radio-1"]:checked~.glider {
        transform: translateX(0);
    }

    input[id="radio-2"]:checked~.glider {
        transform: translateX(120%);
    }

    input[id="radio-3"]:checked~.glider {
        transform: translateX(230%);
    }

    .rightrectangle {
        width: calc(180% - 0px);
        margin-left: -50px;
    }

    .rtext {
        font-size: 15px;
    }

    .rprice {
        font-size: 22px;
    }

    .lefttext {
        font-size: 25px;
        margin-top: -27px;
    }


}

@media (max-width:410px) {
    .tab {
        font-size: 12px;
        width: 60px;
    }

    .glider {
        width: 60px;
    }

    input[id="radio-1"]:checked~.glider {
        transform: translateX(0%);
    }

    input[id="radio-2"]:checked~.glider {
        transform: translateX(100%);
    }

    input[id="radio-3"]:checked~.glider {
        transform: translateX(200%);
    }

    .btrectangle {
        width: calc(110% - 0px);
    }

    .btrectangle2 {
        width: calc(110% - 0px);
    }

    .rightrectangle {
        width: calc(110% - 0px);
        margin-left: 10px;
    }

    .rtext {
        font-size: 15px;
    }

    .rprice {
        font-size: 22px;
    }

    .lefttext {
        font-size: 25px;
        margin-top: -27px;
    }


}