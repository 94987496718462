/* Add this CSS to your component's CSS file or style block */
.editable-text-box {
    position: relative;
    width: 300px;

    /* Adjust the width as desired */
}

.editable-text-box .non-editable-text {
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    /* Push the icon to the right */
    border: 2px solid #0000004d;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}

.editable-text-box .edit-button,
.editable-text-box .save-button {
    background-color: transparent;
    color: black;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.editable-text-box .edit-mode-container {
    display: flex;
    align-items: center;
}

.edit-mode-container {
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: space-between;
    /* Push the icon to the right */
    border: 2px solid #0000004d;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;
}