.primary-select {
    height: 45px;
    width: 305px;
    border-radius: 10px;
    border: 2px solid #0000004d;
    font-family: Space Grotesk;
    padding-inline: 10px;
    background-color: white;
    font-weight: 500;
}

SELECT {
    background: url("data:image/svg+xml,<svg height='15px' width='15px' viewBox='0 0 16 16' fill='%23000000'  xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
    width: 906px;
    height: 40px;
    border-radius: 7px;
    padding-left: 7px;
    border: 1px solid black;
}

.pr_select {
    height: 90px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0px 10px 10px 10px;
}

.pr_input {
    height: 90px;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0px 10px 10px 10px;
}

.txtarea {
    border-radius: 8px;
    border: 2px solid #0000004d;
    padding: 10px;
}

.lab {
    margin-bottom: 0rem !important;
    margin-bottom: 0rem;
    font-family: "Space Grotesk";
    font-weight: 600;
    padding-left: 5px;
    color: black;
    font-size: 18px;
}