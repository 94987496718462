.topnav {
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-mainhead {
    margin-left: 100px;
    font-weight: 600;
}

.notifications {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    border-radius: 15px;
    cursor: pointer;
}

span {
    /* font-style: italic; */
    font-weight: 500;
}

.right-nav {
    display: flex;
    align-items: center;
    width: 218px;
    justify-content: space-evenly;
}

.profile {
    width: 95px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    z-index: 100;
    position: relative;
}

svg {
    cursor: pointer;
}

.profile-pic {
    border-radius: 45px;
    cursor: pointer;
}

.opt-sub-head {
    font-size: 13px;
    font-weight: 500;
}

.opt1,
.opt2 {
    width: 100%;
    height: 50x;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.profile-options {
    width: 117px;
    height: 168px;
    border-radius: 15px;
    background-color: white;
    z-index: 0;
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
}

.nav-mainhead-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}