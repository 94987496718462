.in_box2 {
    width: 45%;
    height: 74%;
    border: 2px solid black;
    border-radius: 16px;
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
    flex-direction: column;
    flex-shrink: 0;
    gap: 30px;
    align-items: center;
    justify-content: center;
}