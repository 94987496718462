
.mslider {
    width: 300%;
    height: 10px;
    background-color: white; 
    border-radius: 5px;
    outline: none;
    cursor: pointer;
  }

  .mslider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background-color: white; 
    border-radius: 50%;
    cursor: pointer;
  }

  .mslider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 50%;
    cursor: pointer;
  }
  

  @media (max-width:820px) {

    .mslider {
      width: 200%;
    }
    
    
    
    }

    
  @media (max-width:510px) {

    .mslider {
      width: 200%;
    }
    
    
    
    }