@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600;800&display=swap");

* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
	/* outline: 1px solid red; */
}

a {
	text-decoration: none;
}

body {
	font-family: "Inter", sans-serif;
	background: #F0F0F0;
}

.sidebar-component {
	height: 100vh;
	width: fit-content;
	float: left;
	display: inline;
	position: fixed;
	z-index: 100;
}

.navbar-component {
	padding-top: 15px;
}



.card-component1 {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin-left: 75px;
	overflow: hidden;
}

#card1 {
	background-color: #4169e1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
}

#card2 {
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: column;
}

#card3 {
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	flex-direction: column;
}

.comp1,
.comp2 {
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	width: 718px;
}

.card-component2 {
	margin-left: 75px;
	margin-top: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
}

@media (max-width: 1384px) {
	.comp3 {
		width: 100%;
		flex-direction: column;
	}

	.data_card_1 {
		margin-bottom: 50px;
	}
}

@media (max-width: 1322px) {
	.card-component1 {
		flex-direction: column;
	}
}

@media (max-width: 800px) {
	.sidebar-component {
		position: fixed;
		z-index: 100;
	}

	h2.nav-mainhead {
		display: none;
	}

	.topnav {
		justify-content: flex-end;
	}

	/* .sidebar{
        background-color: #f2f2fd !important;
    }
    .icons, .setting-menu{
        display: none;
    }
    .burger-menu{
        margin-top: 10px;
    } */
}

@media (max-width: 720px) {

	.comp1,
	.comp2 {
		flex-direction: column;
	}
}

@media (max-width: 388px) {
	.cards {
		height: 322px;
		width: 275px;
		background-color: white;
		border-radius: 15px;
		margin-top: 30px;
	}
}